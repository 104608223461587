import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ContactForm from '../components/ContactForm'
import Layout from '../components/DefaultLayout';
import Img from "../components/DogCard";
import styled from "styled-components";

const Image = styled.img`
  width: 50%;
  padding: 25px;
  margin: auto;
`
const Cont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
    font-size: 1.25rem;
`

const Contact = ({data}) => {
    return (
        <Layout>
            <div>
                <Helmet>
                    <title>{`Kontakt - ${config.siteTitle}`}</title>
                    <meta property="og:title" content={`Kontakt - ${config.siteTitle}`}/>
                    <meta property="og:url" content={`${config.siteUrl}/contact/`}/>
                </Helmet>

                <Container>
                    <PageTitle>Kontakt</PageTitle>
                    <Cont>
                    <h2>Frank Ney</h2>
                    <h2>Tel.: <a href="tel:+4915153961818">+49 151 539 618 18</a></h2>
                    </Cont>
                    <Image src="https://images.ctfassets.net/v9p9nndrs2kj/1g8xq9hXXBVuUUUAS0cowy/e453465fe6f01d33590186460204be2e/55713707_256317861807157_2500997054604509184_n.jpg?h=500" backgroundColor={'#eeeeee'} />
                    <ContactForm/>
                </Container>
            </div>
        </Layout>
    )
}

export default Contact
